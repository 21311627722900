import React from "react";
import Article from "../component/Article";
import Layout from "../hoc/Layout";
import termsData from "../utilities/app-data/about.json";

const About = () => {
  return (
    <Layout headerBanner={null}>
      <div className="container">
        {termsData.map((itm, id) => (
          <Article title={itm.title} key={id}>
            <p
              dangerouslySetInnerHTML={{
                __html: itm.content,
              }}
            ></p>
          </Article>
        ))}
      </div>
    </Layout>
  );
};

export default About;
