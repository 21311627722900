import React from "react";
import Navbar from "./Navbar";
import { HeaderProps } from "../utilities/types";

const Header = ({ type }: HeaderProps) => (
  <header className={`header ${type ? "header--home" : "header--dashboard"}`}>
    <Navbar />
    <div className="header__caption">
      {type ? (
        <>
          <p className="heading-pry">
            Explore for a more <span className="text-sec">accurate</span>{" "}
            predictions
          </p>
          <a href="#prediction" className="btn btn-round btn-pry">
            Get free tips
          </a>
        </>
      ) : (
        <p>
          In the realm of gaming, every choice offers its unique experience.
          Let's proceed with payment to embark on your chosen adventure.
        </p>
      )}
    </div>
  </header>
);

export default Header;
