import React from "react";
import { ProfileItemProps } from "../utilities/types";

const ProfileItem = ({ title, value }: ProfileItemProps) => (
  <tr className="prediction__row">
    <th>{title}</th>
    <td>{value}</td>
  </tr>
);

export default ProfileItem;
