import React, { useEffect, useState } from "react";
import Layout from "../hoc/Layout";
import Subscription from "../component/Subscription";
import PageHeader from "../component/PageHeader";
import { axiosInstance } from "../utilities/helpers";
import { PageLoader } from "../component/Loader";
import { SubscriptionResponse, Subscriptions } from "../utilities/types";
import { AxiosResponse } from "axios";
import { useAppDispatch, useTypedSelector } from "../store/store";
import { updateSubscriptionCount } from "../store/slices/authSlice";

const SUBSCRIPTION_URL = "/subscriptions";

const Pricing = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState<Subscriptions[]>([]);
  const { accessToken } = useTypedSelector((state) => state.auth);

  useEffect(() => {
    const fetchSubscriptions = () => {
      setIsLoading(true);
      axiosInstance(accessToken)
        .get<SubscriptionResponse>(SUBSCRIPTION_URL)
        .then(({ data }: AxiosResponse<SubscriptionResponse>) => {
          const { count } = data;
          dispatch(updateSubscriptionCount({ subscription_count: count }));
          setSubscriptions(data.subscriptions);
        })
        .catch((err) => console.error("Error fetching subscriptions:", err))
        .finally(() => setIsLoading(false));
    };

    fetchSubscriptions();
  }, [accessToken, dispatch]);

  if (isLoading) return <PageLoader />;

  return (
    <Layout headerBanner={null}>
      <div className="container">
        <div className="main-content">
          <div className="subscription">
            <PageHeader
              headline="Choose your plan"
              caption="At <span className='text-sec'>Betuptip</span>, we offer flexible subscription plans tailored to meet your needs."
            />
            <div className="subscription__block">
              {subscriptions.map((plan) => (
                <Subscription subscription={plan} key={plan._id} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Pricing;
