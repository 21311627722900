import React from "react";
import { Link } from "react-router-dom";
import { ButtonProps } from "../utilities/types";

const Btn = ({ className, children, href }: ButtonProps) => (
  <Link to={href} className={className}>
    {children}
  </Link>
);

export default Btn;
