import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { toggleMenu } from "../utilities/helpers";
import { logoutUser } from "../utilities/helpers";
import { useTypedSelector } from "../store/store";
import NavbarItem from "./NavbarItem";

const BASEURL = `${window.location.origin}`;
const ROUTES = {
  HOME: "/",
  DASHBOARD: "/dashboard",
  SUBSCRIPTIONS: "/pricing",
  REGISTER: "/register",
  LOGIN: "/login",
  PROFILE: "/profile",
};

const navLinks = [
  { name: "Home", link: ROUTES.HOME },
  { name: "Dashboard", link: ROUTES.DASHBOARD },
  { name: "Subscriptions", link: ROUTES.SUBSCRIPTIONS },
  { name: "Register", link: ROUTES.REGISTER },
  { name: "Login", link: ROUTES.LOGIN },
  { name: "Profile", link: ROUTES.PROFILE },
];

const Navbar = () => {
  const navigate = useNavigate();
  const { is_loggedIn, subscription_count } = useTypedSelector(
    (state) => state.auth
  );

  const Logout = () => {
    logoutUser(navigate);
  };

  return (
    <nav className="navigation">
      <div className="container">
        <div className="navigation__wrap">
          <div>
            <div className="navigation__logo">
              <Link to={ROUTES.HOME}>
                <img src={`${BASEURL}/assets/img/logo.svg`} alt="logo" />
              </Link>
            </div>
          </div>
          <div>
            <ul className="nav">
              {!is_loggedIn
                ? navLinks
                    .filter(
                      (itm) =>
                        itm.link !== ROUTES.DASHBOARD &&
                        itm.link !== ROUTES.PROFILE
                    )
                    .map((navLink) => (
                      <NavbarItem
                        key={navLink.link}
                        link={navLink.link}
                        name={navLink.name}
                        className={
                          navLink.link === ROUTES.LOGIN
                            ? "btn btn-pry btn-round"
                            : "btn btn-outline-light btn-round"
                        }
                      />
                    ))
                : navLinks
                    .filter(
                      (itm) =>
                        itm.link !== ROUTES.LOGIN &&
                        itm.link !== ROUTES.REGISTER
                    )
                    .map((navLink) =>
                      !subscription_count &&
                      navLink.link === ROUTES.SUBSCRIPTIONS ? null : (
                        <NavbarItem
                          link={navLink.link}
                          name={navLink.name}
                          key={navLink.link}
                        />
                      )
                    )}

              {is_loggedIn && (
                <li className="nav__item">
                  <Link to="#" className="nav__link" onClick={Logout}>
                    Logout
                  </Link>
                </li>
              )}
            </ul>
            <div className="hamburger" onClick={toggleMenu}>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
