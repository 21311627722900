import React, { useRef } from "react";
import { otpProps } from "../utilities/types";

const Otp = ({ otp, setOtp }: otpProps) => {
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    setOtp(name, value);

    if (value.length === 1 && index < inputsRef.current.length - 1) {
      inputsRef.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && (e.target as HTMLInputElement).value === "") {
      if (index > 0) {
        inputsRef.current[index - 1]?.focus();
      }
    }
  };

  return (
    <div className="input__group">
      <div className="otp-flex">
        {Object.keys(otp).map((key, index) => (
          <input
            key={key}
            type="text"
            id={key}
            name={key}
            autoComplete="off"
            maxLength={1}
            value={(otp as any)[key]}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            ref={(el) => (inputsRef.current[index] = el)}
          />
        ))}
      </div>
    </div>
  );
};

export default Otp;
