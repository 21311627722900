import React from "react";
import Btn from "./Btn";

const Newsletter = () => {
  return (
    <div className="newsletter" id="newsletter">
      <div className="newsletter__content">
        <h3 className="text-sec my-2">Suscribe to Our Newsletter</h3>
        <p>
          Subscribe to our newsletter and be ahead of the curve with exclusive
          insights, early access to product launches, special offers, and
          invaluable resources on predictions!
        </p>
        <form action="#" className="subscribe">
          <input
            type="text"
            className="subscription__input"
            placeholder="input your email address"
          />
          <Btn href="/pricing" className="btn btn-pry btn--round">
            Suscribe
          </Btn>
        </form>
      </div>
      <div className="newsletter__img">
        <img src="assets/img/image-removebg-preview (90) 1.png" alt="" />
      </div>
    </div>
  );
};

export default Newsletter;
