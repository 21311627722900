import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { NavigateFunction } from "react-router-dom";
import { SetStateAction } from "react";
import { Alert, ErrorState } from "./types";
import { store } from "../store/store";
import { logout } from "../store/slices/authSlice";

export const toggleMenu = () => {
  const navMenu = document.querySelector(".nav");
  const hamburger = document.querySelector(".hamburger");

  if (navMenu && hamburger) {
    navMenu.classList.toggle("active");
    hamburger.classList.toggle("active");
  }
};

export const logoutUser = (
  navigate: NavigateFunction,
  url: string = "/login"
) => {
  store.dispatch(logout());
  navigate(url);
  toggleMenu();
};

export const handleError = <T extends ErrorState = Alert>(
  error: unknown,
  navigate: NavigateFunction,
  updateState: (value: SetStateAction<T>) => void
) => {
  const err = error as AxiosError;

  if (!err.response || !err.response?.data) {
    updateState((prevState) => ({
      ...prevState,
      status: false,
      message: "An error occurred, please try again",
    }));

    return;
  }

  if (err.response.status === 401) {
    logoutUser(navigate);
    return;
  }

  const errorData = err.response.data as {
    status: boolean;
    message: string;
  };

  updateState((prevState) => ({
    ...prevState,
    status: false,
    message: errorData.message || "An error occurred",
  }));
};

export const formatCurrency = (amount: number, currency: string = "NGN") => {
  return new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency,
  }).format(amount);
};

export const axiosInstance = (token?: string, param?: string): AxiosInstance =>
  axios.create({
    timeout: 30000,
    baseURL: "https://api.betuptip.com/v1",
    headers: {
      "Content-Type": "application/json",
      "usebetup-token": token,
      params: param,
    },
  });

export const axiosConfig = (
  accessToken?: string,
  baseURL: string = "https://api.betuptip.com/v1",
  timeout: number = 30000
): AxiosRequestConfig => ({
  baseURL,
  timeout,
  headers: {
    "usebetup-token": accessToken,
    "Content-Type": "application/json",
  },
});

// "https://api.betuptip.com/v1",
// "http://127.0.0.1:9600/v1",
