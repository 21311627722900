import React, { useEffect, FormEvent } from "react";
import Layout from "../hoc/Layout";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../store/store";
import { setEmail } from "../store/slices/authSlice";
import { ButtonLoader } from "../component/Loader";
import useAxiosFetch from "../Hooks/useAxiosFetch";
import useForm from "../Hooks/useForm";
import FormInput from "../component/FormInput";
import Alert from "../component/Alert";
import useAlert from "../Hooks/useAlert";
import { axiosConfig } from "../utilities/helpers";
import { VerificationResponse } from "../utilities/types";
const RESET_URL = "/auth/forgot-password";
const RESEND_URL = "/auth/resend-password-otp";

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const { formState, handleChange } = useForm({ email: "" });
  const { data, loading, error, fetchData, resetData } = useAxiosFetch<
    VerificationResponse,
    { email: string }
  >();

  const [alert, setAlert] = useAlert(data, error);

  useEffect(() => {
    dispatch(setEmail({ email: formState.email }));
  }, [dispatch, formState.email]);

  useEffect(() => {
    if (data) {
      setAlert(data);
      resetData();
    }
  }, [data, resetData, setAlert]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleChange(name as keyof typeof formState, value);
  };

  const resetPassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetchData(RESET_URL, "POST", axiosConfig(""), formState);
  };

  const resendOTP = () => {
    resetData();
    fetchData(RESEND_URL, "POST", axiosConfig(""), formState);
  };

  return (
    <Layout headerBanner={null}>
      <div className="container">
        <Alert content={alert} />
        <div className="main-content">
          <div className="register">
            <div className="register__img"></div>
            <div className="register__form register--top">
              <h3>Forgot password</h3>
              <form className="form" onSubmit={resetPassword}>
                <FormInput
                  id="email"
                  type="email"
                  label="Email"
                  value={formState.email}
                  onChange={handleInputChange}
                />
                <div className="input__group">
                  <button
                    type="submit"
                    className="btn btn-pry btn-lrg btn-round btn-block"
                  >
                    {loading ? <ButtonLoader /> : "Submit"}
                  </button>
                </div>
                <div className="input__group">
                  <div className="flex flex--between">
                    <p>
                      did not get an otp?
                      <Link to="#" onClick={resendOTP}>
                        resend otp
                      </Link>
                    </p>
                    <p className="text-right">
                      <a href="/forgot-password-verify">Verify OTP</a>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ForgotPassword;
