import React, { FormEvent, useEffect } from "react";
import Layout from "../hoc/Layout";
import Otp from "../component/Otp";
import { useNavigate } from "react-router-dom";
import { useTypedSelector } from "../store/store";
import { ButtonLoader } from "../component/Loader";
import useAxiosFetch from "../Hooks/useAxiosFetch";
import useForm from "../Hooks/useForm";
import { VerificationResponse, OtpProps } from "../utilities/types";
import { axiosConfig } from "../utilities/helpers";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const { email, accessToken } = useTypedSelector((state) => state.auth);
  const { formState, handleChange } = useForm({
    dig1: "",
    dig2: "",
    dig3: "",
    dig4: "",
    dig5: "",
    dig6: "",
  });

  const { data, loading, error, fetchData, resetData } = useAxiosFetch<
    VerificationResponse,
    OtpProps
  >();

  useEffect(() => {
    if (data) {
      resetData();
    }

    if (error) {
      console.log(error);
      resetData();
    }
  }, [data, error, navigate, resetData]);

  const verifyEmail = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetchData("/auth/verify-password-otp", "POST", axiosConfig(accessToken), {
      email: `${email}`,
      otp: `${formState.dig1}${formState.dig2}${formState.dig3}${formState.dig4}${formState.dig5}${formState.dig6}`,
    });
  };

  const handleInputChange = (name: string, value: string) => {
    handleChange(name as keyof typeof formState, value);
  };

  return (
    <Layout headerBanner={null}>
      <div className="container">
        <div className="verify">
          <header className="heading">
            <h3 className="txt-primary">Email verification</h3>
            <p>please enter the code we have sent to your email</p>
          </header>
          <form className="verify-block" onSubmit={verifyEmail}>
            <div className="input__group">
              <label htmlFor="otp-email" className="txt-primary">
                please enter code below
              </label>
              <Otp otp={formState} setOtp={handleInputChange} />
            </div>
            <div className="input__group txt-center">
              <button type="submit" className="btn btn--round btn-pry btn--lrg">
                {loading ? <ButtonLoader /> : "Verify"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default VerifyEmail;
