import React from "react";
import Article from "../component/Article";
import Layout from "../hoc/Layout";
import termsData from "../utilities/app-data/terms&condition.json";
import PageHeader from "../component/PageHeader";

const Terms = () => {
  return (
    <Layout headerBanner={null}>
      <div className="container">
        <div className="main-content">
          <PageHeader headline="Terms & Conditions" />
          {termsData.map((itm, id) => (
            <Article title={itm.title} key={id}>
              <p
                dangerouslySetInnerHTML={{
                  __html: itm.content,
                }}
              ></p>
            </Article>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Terms;
