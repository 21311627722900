import React, { FormEvent, useEffect, useState } from "react";
import Layout from "../hoc/Layout";
import { Link, useNavigate } from "react-router-dom";
import useAlert from "../Hooks/useAlert";
import Alert from "../component/Alert";
import { useAppDispatch, useTypedSelector } from "../store/store";
import useForm from "../Hooks/useForm";
import FormInput from "../component/FormInput";
import { axiosInstance, handleError } from "../utilities/helpers";
import { ButtonLoader, PageLoader } from "../component/Loader";
import ProfileItem from "../component/ProfileItem";
import { ProfileInformation, ProfileResponseData } from "../utilities/types";
import { AxiosResponse } from "axios";
import { updateSubscriptionCount } from "../store/slices/authSlice";

const PROFILE_URL = "/users";
const UPDATE_EMAIL_URL = "/users/email";
const CHANGE_PASSWORD_URL = "/users/password";

const Profile = () => {
  const [p_loader, setPLoader] = useState(false);
  const [e_loader, setELoader] = useState(false);
  const [pass_loader, setPassLoader] = useState(false);
  const [del_loader, setDelLoader] = useState(false);
  const [alert, setAlert] = useAlert();
  const [profile_info, setProfileInfo] = useState<ProfileInformation | null>(
    null
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { accessToken } = useTypedSelector((state) => state.auth);
  const { formState, handleChange } = useForm({
    email: "",
  });

  const { formState: passState, handleChange: handleChanges } = useForm({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    setPLoader(true);

    const getProfileInfo = () => {
      axiosInstance(accessToken)
        .get<ProfileResponseData>(PROFILE_URL)
        .then(({ data }: AxiosResponse<ProfileResponseData>) => {
          const { subscription_count } = data;
          dispatch(updateSubscriptionCount({ subscription_count }));
          setProfileInfo(data.user);
        })
        .catch((err) => handleError(err, navigate, setAlert))
        .finally(() => setPLoader(false));
    };

    getProfileInfo();
  }, [accessToken, dispatch, navigate, setAlert]);

  const changeEmail = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setELoader(true);

    axiosInstance(accessToken)
      .patch(UPDATE_EMAIL_URL, formState)
      .then((resp: any) =>
        setAlert({
          message: resp.data.message || "Email updated successfully",
          status: true,
        })
      )
      .catch((err) => handleError(err, navigate, setAlert))
      .finally(() => setELoader(false));
  };

  const changePassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPassLoader(true);

    axiosInstance(accessToken)
      .patch(CHANGE_PASSWORD_URL, passState)
      .then((resp: any) =>
        setAlert({
          message: resp.data.message || "Password updated successfully",
          status: true,
        })
      )
      .catch((err) => handleError(err, navigate, setAlert))
      .finally(() => setPassLoader(false));
  };

  const deleteUser = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDelLoader(true);

    axiosInstance(accessToken)
      .delete(PROFILE_URL)
      .catch((er) =>
        setAlert({
          message: er.response?.data?.message || "An error occurred",
          status: false,
        })
      )
      .finally(() => setDelLoader(false));
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleChange(name as keyof typeof formState, value);
  };

  const handlePassChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleChanges(name as keyof typeof passState, value);
  };

  if (p_loader) return <PageLoader />;
  return (
    <Layout headerBanner={null}>
      <div className="container">
        <Alert content={alert} />
        <div className="main-content">
          <div className="profile">
            <div className="profile__wrap">
              <div className="profile__nav">
                <div className="navbar">
                  <div className="navbar__section">
                    <h4>Account</h4>
                    <ul>
                      <li>
                        <Link to={"/profile"}>Account Information</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="profile__content">
                <div className="card">
                  <h3 className="card__title">Profile Details</h3>
                  <div className="tip__flex">
                    <table className="prediction">
                      <tbody className="prediction__body justify-left">
                        <ProfileItem
                          title="Fullname"
                          value={`${profile_info?.firstname} ${profile_info?.lastname}`}
                        />
                        <ProfileItem
                          title="Email"
                          value={profile_info?.email}
                        />
                        <ProfileItem
                          title="Phone"
                          value={profile_info?.phone}
                        />
                        <ProfileItem
                          title="Subscription Plan(s)"
                          value={profile_info?.subscription_plan}
                        />
                        <ProfileItem
                          title="Telegram ID"
                          value={profile_info?.telegram_id}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card card-secoundry">
                  <h3 className="card__title">Change Email</h3>
                  <form onSubmit={changeEmail}>
                    <FormInput
                      id="email"
                      type="email"
                      label="New Email"
                      value={formState.email}
                      onChange={handleEmailChange}
                    />
                    <button
                      className="btn btn-pry btn-round btn-block"
                      type="submit"
                      disabled={e_loader}
                    >
                      {e_loader ? <ButtonLoader /> : "Change Email"}
                    </button>
                  </form>
                </div>
                <div className="card card-secoundry">
                  <h3 className="card__title">Change Password</h3>
                  <form onSubmit={changePassword}>
                    <FormInput
                      id="oldPassword"
                      type="password"
                      label="Old Password"
                      value={passState.oldPassword}
                      onChange={handlePassChange}
                    />
                    <FormInput
                      id="newPassword"
                      type="password"
                      label="New Password"
                      value={passState.newPassword}
                      onChange={handlePassChange}
                    />
                    <FormInput
                      id="confirmPassword"
                      type="password"
                      label="Confirm Password"
                      value={passState.confirmPassword}
                      onChange={handlePassChange}
                    />
                    <button
                      className="btn btn-pry btn-round btn-block"
                      type="submit"
                      disabled={pass_loader}
                    >
                      {pass_loader ? <ButtonLoader /> : "Change Password"}
                    </button>
                  </form>
                </div>
                <div className="card card-danger">
                  <h3 className="card__title">Delete Account</h3>
                  <p>
                    Once you delete your account, there is no chance of recovery
                  </p>
                  <form onSubmit={deleteUser}>
                    <button
                      className="btn btn-danger btn-round btn-block"
                      type="submit"
                      disabled={del_loader}
                    >
                      {del_loader ? <ButtonLoader /> : "Delete Account"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
