import React, { ReactNode } from "react";
import Header from "../component/Header";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";

type layoutProp = {
  headerBanner: boolean | null;
  children: ReactNode;
};

const Layout = ({ headerBanner, children }: layoutProp) => (
  <>
    {headerBanner === null ? (
      <Navbar />
    ) : headerBanner ? (
      <Header type={headerBanner} />
    ) : (
      <Header type={headerBanner} />
    )}
    <main>{children}</main>
    <Footer />
  </>
);

export default Layout;
