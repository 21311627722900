import React from "react";
import { ArticleProps } from "../utilities/types";

const Article = ({ title, children }: ArticleProps) => (
  <article className="odds__info">
    <h3 className="text-sec">{title}</h3>
    {children}
  </article>
);

export default Article;
