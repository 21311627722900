import React from "react";

const SocialLoginButton = () => (
  <div className="form__socials">
    <div className="input__group">
      <button className="btn btn-outline-dark btn-lrg btn-round btn-block">
        <div className="btn-img">
          <img src="assets/img/google-logo.png" alt="google logo" />
        </div>
        <span>Continue with Google</span>
      </button>
    </div>
  </div>
);

export default SocialLoginButton;
