import { useEffect, useState, useCallback } from "react";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { HttpMethod, FetchError } from "../utilities/types";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../utilities/helpers";

const defaultConfig: AxiosRequestConfig = {
  baseURL: "https://api.betuptip.com/v1",
  headers: {
    "Content-Type": "application/json",
  },
};

const useAxiosFetch = <T, U = any>(
  initialUrl?: string,
  initialMethod: HttpMethod = "GET",
  initialConfig: AxiosRequestConfig = defaultConfig,
  initialRequestData?: U
) => {
  const navigate = useNavigate();
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<FetchError | null>(null);
  const [url, setUrl] = useState<string | undefined>(initialUrl);
  const [method, setMethod] = useState<HttpMethod>(initialMethod);
  const [config, setConfig] = useState<AxiosRequestConfig>(initialConfig);
  const [requestData, setRequestData] = useState<U | undefined>(
    initialRequestData
  );

  const makeRequest = useCallback(async () => {
    if (!url || !method) return;
    setLoading(true);

    try {
      let response: AxiosResponse<T>;

      switch (method) {
        case "GET":
          response = await axios.get<T>(url, config);
          break;
        case "POST":
          response = await axios.post<T>(url, requestData, config);
          break;
        case "PATCH":
          response = await axios.patch<T>(url, requestData, config);
          break;
        case "DELETE":
          response = await axios.delete<T>(url, config);
          break;
        default:
          throw new Error("Unsupported HTTP method");
      }

      setData(response.data);
    } catch (err) {
      const axiosError = err as AxiosError;

      if (axiosError.response && typeof axiosError.response.data === "object") {
        const errorData = axiosError.response.data as {
          message: string;
          status: boolean;
        };

        if (axiosError.response.status === 401) {
          logoutUser(navigate);
        }

        setError({
          message: errorData.message,
          status: errorData.status,
        });
      } else {
        setError({
          message: "Unable to process request, please try again",
          status: false,
        });
      }
    } finally {
      setLoading(false);
    }
  }, [url, method, config, requestData, navigate]);

  useEffect(() => {
    makeRequest();
  }, [makeRequest]);

  const fetchData = useCallback(
    (
      newUrl: string,
      newMethod: HttpMethod,
      newConfig?: AxiosRequestConfig,
      newRequestData?: U
    ) => {
      setUrl(newUrl);
      setMethod(newMethod);
      setRequestData(newRequestData);
      setConfig((prevConfig) => ({ ...prevConfig, ...newConfig }));
    },
    []
  );

  const resetData = () => {
    setData(null);
    setLoading(false);
    setError(null);
  };

  return { data, loading, error, fetchData, resetData };
};

export default useAxiosFetch;
