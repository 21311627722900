import React from "react";

export const ButtonLoader = () => (
  <div className="spinner">
    <div className="spinner-blade"></div>
    <div className="spinner-blade"></div>
    <div className="spinner-blade"></div>
    <div className="spinner-blade"></div>
    <div className="spinner-blade"></div>
    <div className="spinner-blade"></div>
    <div className="spinner-blade"></div>
    <div className="spinner-blade"></div>
    <div className="spinner-blade"></div>
    <div className="spinner-blade"></div>
    <div className="spinner-blade"></div>
    <div className="spinner-blade"></div>
  </div>
);

export const PageLoader = () => (
  <div className="loader">
    <div className="justify-content-center jimu-primary-loading"></div>
  </div>
);
