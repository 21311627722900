import { createSlice, current } from "@reduxjs/toolkit";
import { authType } from "../../utilities/types";

export const getState = (
  stateKey: string,
  initialState: authType
): authType => {
  const cachedState = JSON.parse(localStorage.getItem("app_state") || "{}") as {
    [key: string]: authType;
  };

  return cachedState[stateKey] || initialState;
};

export const saveState = (stateKey: string, updatedState: authType) => {
  const currentAppState = JSON.parse(localStorage.getItem("app_state") || "{}");

  localStorage.setItem(
    "app_state",
    JSON.stringify({
      ...currentAppState,
      [stateKey]: updatedState,
    })
  );
};

const initialState: authType = {
  is_premium: false,
  is_loggedIn: false,
  subscription_count: 3,
  email: undefined,
  accessToken: undefined,
};

const state = getState("auth", initialState);

export const authSlice = createSlice({
  name: "auth",
  initialState: state,
  reducers: {
    login(state, { payload }) {
      state.is_loggedIn = true;
      state.email = payload.email;
      state.accessToken = payload.token;
      state.is_premium = payload.is_premium;
      state.subscription_count = payload.subscription_count; // Corrected this line
      saveState("auth", current(state));
    },

    logout(state) {
      state.is_premium = state.is_loggedIn = false;
      state.accessToken = state.email = undefined;
      state.subscription_count = 3; // Corrected this line
      saveState("auth", current(state));
    },

    updateIsPremium(state, { payload }) {
      state.is_premium = payload.is_premium;
      saveState("auth", current(state));
    },

    updateSubscriptionCount(state, { payload }) {
      state.subscription_count = payload.subscription_count;
      saveState("auth", current(state));
    },

    setEmail(state, { payload }) {
      state.email = payload.email;
      saveState("auth", current(state));
    },
  },
});

export const {
  login,
  logout,
  setEmail,
  updateIsPremium,
  updateSubscriptionCount,
} = authSlice.actions;

export default authSlice.reducer;
