import React, { useEffect, useState } from "react";
import Article from "../component/Article";
import Layout from "../hoc/Layout";
import Predictions from "../component/Predictions";
import articleData from "../utilities/app-data/articles.json";
import { useAppDispatch, useTypedSelector } from "../store/store";
import { TodaysPrediction, TodaysPredictionResponse } from "../utilities/types";
import { PageLoader } from "../component/Loader";
import { axiosInstance, handleError } from "../utilities/helpers";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import useAlert from "../Hooks/useAlert";
import { updateSubscriptionCount } from "../store/slices/authSlice";

const TODAYS_URL = "/predictions/today";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [, setAlert] = useAlert();
  const [predictions, setPredictions] = useState<TodaysPrediction[]>([]);

  const { accessToken, is_premium } = useTypedSelector((state) => state.auth);

  useEffect(() => {
    const fetchTodaysPredictions = () => {
      setIsLoading(true);
      axiosInstance(accessToken)
        .get<TodaysPredictionResponse>(TODAYS_URL)
        .then(({ data }: AxiosResponse<TodaysPredictionResponse>) => {
          const { subscription_count } = data;
          dispatch(updateSubscriptionCount({ subscription_count }));
          setPredictions(data.predictions);
        })
        .catch((err) => handleError(err, navigate, setAlert))
        .finally(() => setIsLoading(false));
    };

    fetchTodaysPredictions();
  }, [accessToken, dispatch, navigate, setAlert]);

  if (isLoading) return <PageLoader />;

  return (
    <Layout headerBanner={false}>
      <div className="container">
        <div className="dashboard">
          <section className="odds">
            <div className="odds__header">
              <h3>
                {!is_premium ? "Free Predictions" : `Premium Predictions`}
              </h3>
            </div>

            {predictions.length ? (
              predictions.map((itm) => (
                <div className="tip" id="prediction" key={itm._id}>
                  <h3 className="text-sec">{`${itm._id} Games`}</h3>

                  <div className="tip__block">
                    {<Predictions key={itm._id} data={itm.item ?? []} />}
                  </div>
                </div>
              ))
            ) : (
              <div className="tip" id="prediction">
                <h3 className="text-sec">Free Games</h3>

                <div className="tip__block">{<Predictions data={[]} />}</div>
              </div>
            )}
          </section>

          <div className="shift">
            {articleData.map((article, index) => (
              <Article title={article.title} key={index}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: article.content,
                  }}
                />
              </Article>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
