import React from "react";
import { BlockquoteProps } from "../utilities/types";

const Blockquote = ({ title, children }: BlockquoteProps) => (
  <div className="blockquote blockquote-pry mb-3">
    <h3 className="blockquote__title mb-1">{title}</h3>
    <p
      dangerouslySetInnerHTML={{
        __html: children,
      }}
    ></p>
  </div>
);

export default Blockquote;
