import React from "react";
import { Link } from "react-router-dom";

const WHATSAPP_URL = "https://wa.me/+2347076426518";
const INSTAGRAM_URL = "https://www.instagram.com/betuptip";
const X_URL = "https://x.com/betuptip?s=09";

const Footer = () => (
  <footer className="footer">
    <div className="footer__container">
      <div className="footer__wrap">
        <div className="footer__brand">
          <h3 className="text-sec">Enhancing Your Betting Experience</h3>
          <p className="mb-3">
            Visit www.betuptip.com for all your betting needs. Remember ,
            betting can be addictive. Seek help if needed t Gamecare
          </p>
          <p className="age mb-3">
            <img src={`${window.location.origin}/assets/img/18.svg`} alt="18" />
            <span>
              You Must be 18 years old or above to use this website, please bet
              responsibly
            </span>
          </p>
          <p>
            Copyright &copy; 2023 Betuptip. All Rights Reserved. betuptip.com is
            a trading name owned by webfree solutions.
          </p>
        </div>
        <div className="footer__contact">
          <div className="email-block mb-2">
            <h3 className="text-sec">Get in Touch</h3>
            <p>Email</p>
            <p>Support@betuptip.com</p>
          </div>
          <div className="whatsapp-block mb-2">
            <h3 className="text-sec">Whatsapp</h3>
            <Link to={WHATSAPP_URL} className="navLink" target="_blank">
              <p>
                <img
                  src={`${window.location.origin}/assets/img/whatsapp.png`}
                  alt=""
                />
                <span className="number text-white">+2347076426518</span>
              </p>
            </Link>
          </div>
          <div className="social-block">
            <h3 className="text-sec">Social media</h3>
            <div className="icons">
              <Link to={INSTAGRAM_URL} className="navLink" target="_blank">
                <img
                  src={`${window.location.origin}/assets/img/instagram.png`}
                  alt="Instagram"
                />
              </Link>
              <Link to={X_URL} className="navLink" target="_blank">
                <img
                  src={`${window.location.origin}/assets/img/twitter-2.png`}
                  alt="X"
                />
              </Link>
              <Link to={WHATSAPP_URL} className="navLink" target="_blank">
                <img
                  src={`${window.location.origin}/assets/img/twitter.svg`}
                  alt="Whatsapp"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="footer__link">
          <h3 className="text-sec">Quick links</h3>
          <ul className="footer__nav">
            <li className="nav__item">
              <Link to="/about-us" className="navLink">
                About us
              </Link>
            </li>
            <li className="nav__item">
              <Link to="/terms-of-use" className="navLink">
                Terms of Use
              </Link>
            </li>
            <li className="nav__item">
              <Link to="/pricing" className="navLink">
                Pricing
              </Link>
            </li>
            <li className="nav__item">
              <Link to="#" className="navLink">
                Contact us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="footer__img">
      <img
        src={`${window.location.origin}/assets/img/fooer-img.svg`}
        alt="footer"
      />
    </div>
  </footer>
);

export default Footer;
