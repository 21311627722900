import { useState, useEffect } from "react";
import { Alert, FetchError } from "../utilities/types";

const useAlert = (data: any | null = null, error: FetchError | null = null) => {
  const [alert, setAlert] = useState<Alert>({
    status: false,
    message: "",
  });

  useEffect(() => {
    if (data) {
      setAlert({ message: data.message, status: data.status });
    }

    if (error) {
      setAlert({
        message: error.responseData?.message || error.message,
        status: false,
      });
    }
  }, [data, error]);

  return [alert, setAlert] as const;
};

export default useAlert;
