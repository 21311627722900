import React from "react";
import { FormInputProps } from "../utilities/types";

const FormInput = ({ id, type, label, value, onChange }: FormInputProps) => (
  <div className="input__group">
    <label htmlFor={id}>{label}</label>
    <input
      id={id}
      name={id}
      type={type}
      autoComplete="off"
      value={value}
      onChange={onChange}
    />
  </div>
);

export default FormInput;
