import React from "react";
import { predItemProp } from "../utilities/types";

const PredictionItem = ({ data }: predItemProp) => {
  if (!data)
    return (
      <tr className="prediction__notes">
        <td colSpan={4}>
          Tips for Today's Matches are being added... Kindly check back in the
          hours of 6:30 AM - 2:00 PM GMT. Don't make any hasty predictions! NB:
          We take break when there aren't enough matches for the day due to
          international tournaments, mid-season break or cup competitions etc.
        </td>
      </tr>
    );

  return (
    <tr className="prediction__row">
      <td>{data.date}</td>
      <td>{data.prematch}</td>
      <td>{data.event}</td>
      <td>{data.selection}</td>
    </tr>
  );
};

export default PredictionItem;
