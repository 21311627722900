import React, { FormEvent, useEffect } from "react";
import Layout from "../hoc/Layout";
import { useTypedSelector } from "../store/store";
import { useNavigate } from "react-router-dom";
import { ButtonLoader } from "../component/Loader";
import Otp from "../component/Otp";
import useAxiosFetch from "../Hooks/useAxiosFetch";
import useForm from "../Hooks/useForm";
import useAlert from "../Hooks/useAlert";
import Alert from "../component/Alert";
import { axiosConfig } from "../utilities/helpers";
import { VerificationResponse, OtpProps } from "../utilities/types";

const VerifyPasswordReset = () => {
  const navigate = useNavigate();
  const { email } = useTypedSelector((state) => state.auth);
  const { formState, handleChange } = useForm({
    dig1: "",
    dig2: "",
    dig3: "",
    dig4: "",
    dig5: "",
    dig6: "",
  });

  const { data, loading, error, fetchData, resetData } = useAxiosFetch<
    VerificationResponse,
    OtpProps
  >();

  const [alert, setAlert] = useAlert(data, error);

  useEffect(() => {
    if (data) {
      setAlert(data);

      setTimeout(() => {
        resetData();
        navigate("/password-reset");
      }, 2400);
    }
  }, [data, navigate, resetData, setAlert]);

  const handleInputChange = (name: string, value: string) => {
    handleChange(name as keyof typeof formState, value);
  };

  const verifyPassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    fetchData("/auth/verify-password-otp", "POST", axiosConfig(""), {
      email: `${email}`,
      otp: `${formState.dig1}${formState.dig2}${formState.dig3}${formState.dig4}${formState.dig5}${formState.dig6}`,
    });
  };

  return (
    <Layout headerBanner={null}>
      <div className="container">
        <Alert content={alert} />
        <div className="main-content">
          <div className="verify">
            <header className="heading">
              <h3 className="txt-primary">Forgot Password Verification</h3>
              <p>please enter the code we have sent to your email</p>
            </header>
            <form className="verify-block" onSubmit={verifyPassword}>
              <Otp otp={formState} setOtp={handleInputChange} />
              <div className="input__group txt-center">
                <button type="submit" className="btn btn-round btn-pry btn-lrg">
                  {loading ? <ButtonLoader /> : "Verify"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default VerifyPasswordReset;
