import { useState } from "react";

const useForm = <T extends Record<string, any>>(initialState: T) => {
  const [formState, setFormState] = useState<T>(initialState);

  const handleChange = (key: keyof T, value: any) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleNestedChange = (keys: Array<keyof T>, value: any) => {
    setFormState((prevState) => {
      const newState = { ...prevState };
      let currentLevel: any = newState;

      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentLevel[keys[i]]) {
          currentLevel[keys[i]] = {};
        }

        currentLevel = currentLevel[keys[i]];
      }

      currentLevel[keys[keys.length - 1]] = value;
      return newState;
    });
  };

  const handleArrayChange = (key: keyof T, index: number, value: any) => {
    setFormState((prevState) => {
      const updatedArray = [...(prevState[key] as any[])];
      updatedArray[index] = value;

      return {
        ...prevState,
        [key]: updatedArray,
      };
    });
  };

  return {
    formState,
    handleChange,
    handleNestedChange,
    handleArrayChange,
  };
};

export default useForm;
