import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavLinkProps } from "../utilities/types";

const NavbarItem = ({ name, link, className }: NavLinkProps) => {
  const location = useLocation();
  const [is_active, setIsActive] = useState("");

  useEffect(() => setIsActive(location.pathname), [location.pathname]);

  return link !== "/login" && link !== "/register" ? (
    <li className="nav__item">
      <Link
        to={link}
        className={`nav__link ${is_active === link ? "active" : ""}`}
      >
        {name}
      </Link>
    </li>
  ) : (
    <li className="nav__item">
      <Link to={link} className={className}>
        {name}
      </Link>
    </li>
  );
};

export default NavbarItem;
