import React, { FormEvent, useEffect, useState } from "react";
import Layout from "../hoc/Layout";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../store/slices/authSlice";
import { useAppDispatch } from "../store/store";
import { ButtonLoader } from "../component/Loader";
import useAxiosFetch from "../Hooks/useAxiosFetch";
import useForm from "../Hooks/useForm";
import FormInput from "../component/FormInput";
import SocialLoginButton from "../component/SocialLoginButton";
import Alert from "../component/Alert";
import { RegisterResponse, RegisterProps } from "../utilities/types";
import { axiosConfig } from "../utilities/helpers";

const API_ENDPOINT = "/auth/register";
const NAVIGATION_DELAY = 2400;

const Registration = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { formState, handleChange } = useForm({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });

  const [alert, setAlert] = useState({
    status: false,
    message: "",
  });

  const { data, loading, error, fetchData, resetData } = useAxiosFetch<
    RegisterResponse,
    RegisterProps
  >();

  useEffect(() => {
    if (data) {
      setAlert({ message: data.message, status: data.status });
    }

    if (error) {
      setAlert({
        message: error.message,
        status: false,
      });
    }
  }, [data, error]);

  useEffect(() => {
    if (data) {
      const resp = data.user;

      dispatch(
        login({
          token: data.token,
          is_premium: resp.is_premium,
          email: resp.email,
        })
      );

      setTimeout(() => {
        resetData();
        navigate("/dashboard");
      }, NAVIGATION_DELAY);
    }
  }, [data, dispatch, navigate, resetData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleChange(name as keyof typeof formState, value);
  };

  const registerUser = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetchData(API_ENDPOINT, "POST", axiosConfig(""), formState);
  };

  return (
    <Layout headerBanner={null}>
      <div className="container">
        <Alert content={alert} />
        <div className="main-content">
          <div className="register register--register">
            <div className="register__img"></div>
            <div className="register__form">
              <h3>Signup</h3>
              <form className="form" onSubmit={registerUser}>
                <div className="form__group">
                  <FormInput
                    id="firstname"
                    type="text"
                    label="Firstname"
                    value={formState.firstname}
                    onChange={handleInputChange}
                  />
                  <FormInput
                    id="lastname"
                    type="text"
                    label="Lastname"
                    value={formState.lastname}
                    onChange={handleInputChange}
                  />
                </div>
                <FormInput
                  id="email"
                  type="email"
                  label="Email"
                  value={formState.email}
                  onChange={handleInputChange}
                />
                <FormInput
                  id="phone"
                  type="text"
                  label="Phone"
                  value={formState.phone}
                  onChange={handleInputChange}
                />
                <FormInput
                  id="password"
                  type="password"
                  label="Password"
                  value={formState.password}
                  onChange={handleInputChange}
                />
                <FormInput
                  id="confirmPassword"
                  type="password"
                  label="Confirm passwrod"
                  value={formState.confirmPassword}
                  onChange={handleInputChange}
                />
                <div className="input__group">
                  <button
                    type="submit"
                    className="btn btn-pry btn-lrg btn-round btn-block"
                  >
                    {loading ? <ButtonLoader /> : "Submit"}
                  </button>
                </div>
                <div className="input__group">
                  <div className="flex flex--between">
                    <p>
                      have an account already? <Link to="/login">login</Link>
                    </p>
                  </div>
                </div>
                <SocialLoginButton />
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Registration;
